import config from "../config";

class Delay {
    getDelayString(delay) {
        if(typeof delay === "number") {
            return 'zpoždění '+delay+' min'
        }

        return 'zpoždění ? min'
    }

    getDelayColor(delay) {
        if(typeof delay !== "number") {
            return config.DELAY_COLLORS.find(value => value.from === -1).color
        }

        let color = config.DELAY_COLLORS.find(value => value.from === 0).color

        config.DELAY_COLLORS.forEach(value => {
            if(value.from <= delay) {
                color = value.color
            }
        })

        return color
    }
}

export default new Delay()