import React, {useContext, useEffect, useState} from "react";
import { useHistory, useParams } from "react-router-dom";
import axiosInstance, {axiosInstanceOredo} from "../utils/axiosInstance";
import Format from "../utils/Format";
import {MasterContext} from "../MasterContext";
import Delay from "../utils/delay"
import GeoJSON from "../utils/geojson"
import {Notyf} from "notyf";
import LoaderBox from "../components/loaderBox";

const Connection = () => {
    const [connection, setConnection] = useState()
    const [route, setRoute] = useState()
    const [int, setInt] = useState()

    const history = useHistory()

    const context = useContext(MasterContext)

    const notyf = new Notyf({
        duration: 5 * 1000
    });

    const {id} = useParams()

    useEffect(() => {
        setRoute(null)
        setConnection(null)
        context.setGeometry(null)

        update(true)

        if(int) {
            clearInterval(int)
        }

        setInt(setInterval(update, 10000))
    }, [id])

    const update = (first) => {
        axiosInstance.get('/spojeni/'+id)
            .then(response => {
                setConnection(response.data.data)

                if(response.data.data.current_state) {
                    context.setSelectedColor(Delay.getDelayColor(response.data.data.current_state.delay))
                }

                if(response.data.data.waits_for.length > 0) {
                    console.log('waitings', response.data.data.waits_for)
                }

                axiosInstanceOredo.get('/servicedetail?id='+response.data.data.identifier)
                    .then(response => {
                        setRoute(response.data)

                        if(response.data.geometry) {
                            const json = GeoJSON.parseSF(response.data.geometry)

                            if(first) {
                                context.setGeometry(json)
                                context.setSelectedConnection(response.data.data)
                            }
                        }
                    })
            })
            .catch(() => {
                notyf.error('Omlouváme se, v tuto chvíli není možné načíst data o zvoleném spoji')
            })
    }

    const getTimeString = (date) => {
        const h = date.getHours() < 10 ? "0"+date.getHours().toString() : date.getHours().toString()
        const m = date.getMinutes() < 10 ? "0"+date.getMinutes().toString() : date.getMinutes().toString()

        return h + m
    }

    const getMinMax = (delays) => {
        const min = delays.reduce((min, value) => {
            return min > value.delay ? value.delay : min
         }, 8000000000)

        const max = delays.reduce((max, value) => {
            return max < value.delay ? value.delay : max
        }, 0)

        const delayRound = Math.round(delays.reduce((r, value) => {
            return r + value.delay
        }, 0) / delays.length)

        return [min, max, delayRound]
    }

    const getDelays = (delays) => {
        const times = {};

        delays.forEach(delay => {
            const t = times[getTimeString(new Date(delay.start))]
            if(t) {
                times[getTimeString(new Date(delay.start))] = {delay: t.delay + delay.delay, num: t.num + 1}
            } else {
                times[getTimeString(new Date(delay.start))] = {delay: delay.delay, num: 1}
            }
        })


        const delayTimes = {}
        for (const [key, value] of Object.entries(times)) {
            delayTimes[key] = value.delay / value.num
        }

        return delayTimes
    }

    const getDelayFor = (time, delays) => {
        const i = time.split(':')
        const h = i[0] < 10 ? '0'+i[0].toString() : i[0].toString();
        let m = i[1] < 10 ? '0'+i[1].toString() : i[1].toString();
        
        if(parseInt(m.substr(1,1)) >= 5) {
            m = m.substr(0, 1) + "5"
        } else {
            m = m.substr(0, 1) + "0"
        }

        const d = getDelays(delays)

        let delay = null
        for (const [key, value] of Object.entries(d)) {
            if(!delay) {
                delay = value
            }

            if(parseInt(key) <= parseInt(h+m)) {
                delay = value
            }
        }

        return delay;
    }

    return (
        <div className="page-connection">
            <div className="back-homepage">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" onClick={() => history.push('/')}>
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
                </svg>
            </div>
            {(connection && route) ? (
                <>
                    <h2 className="name">
                        {Format.getVehicleType(connection.vehicle_type)} {connection.name}
                        {
                            route.stations.filter(st => st.delay).length > 0 ? (
                                <div className="delay" style={{backgroundColor: Delay.getDelayColor(route.stations.filter(st => st.delay)[0].delay)}}>
                                    {Delay.getDelayString(route.stations.filter(st => st.delay)[0].delay)}
                                </div>
                            ) : (
                                connection.current_state && (
                                <div className="delay" style={{backgroundColor: Delay.getDelayColor(connection.current_state.delay)}}>
                                    {Delay.getDelayString(connection.current_state.delay)}
                                </div>
                                )
                            )
                        }
                    </h2>
                    <div className="provider">
                        {connection.operator} {connection.delays_aggregate && ('| ø běžné zpoždění '+getMinMax(connection.delays_aggregate)[2]+' min ')}
                    </div>

                    <div className="route">
                        <div className="town">
                            <div className="name">{connection.from}</div>
                            <div className="time">{route.stations[0].departureTime}</div>
                        </div>
                        <div className="town">
                            <div className="name">{connection.to}</div>
                            <div className="time">{route.stations[route.stations.length - 1].arrivalTime}</div>
                        </div>
                    </div>

                    <div className="time-table">
                        <div className="title">
                            <div></div>
                            <div className="field">Stanice</div>
                            <div className="field">Př.</div>
                            <div className="field">Odj.</div>
                        </div>
                        <div className="data-frame">
                            <div className="stations">
                                {route && route.stations.map(station => (
                                    <div className="station">
                                        <div className="table">
                                            <div className={station.passed ? "round passed" : "round"}></div>
                                            <div className="station-name">
                                                {station.name}
                                                {connection.delays_aggregate && <div className="delay-round">
                                                    ø {Math.round(getDelayFor(station.departureTime || station.arrivalTime, connection.delays_aggregate))} min
                                                </div>}
                                            </div>
                                            <div className="desc_time">{station.arrivalTime}</div>
                                            <div className="depart_time">{station.departureTime}</div>
                                            <div className="delay">
                                                {station.delay > 0 && Delay.getDelayString(station.delay)}
                                            </div>
                                        </div>
                                        <div className="waiting-for">
                                            {
                                                connection.waits_for
                                                    .filter(waiting => Format.compareStations(station.name, waiting.station))
                                                    .map(waiting => (
                                                    <div className="wait">
                                                        čeká na {Format.getVehicleType(waiting.for.vehicle_type)} {waiting.for.name}
                                                        {/*ztráta:*/}
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="desc">
                            <div className="delay-round">ø průměrné zpoždění ve stanici</div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <h1>Načítání spoje</h1>
                    <LoaderBox/>
                </>
            )}
        </div>
    )
}

export default Connection