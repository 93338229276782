import React, {useEffect, useState} from "react";
import axiosInstance from "./utils/axiosInstance";
import config from "./config";
import { Notyf } from 'notyf';

export const MasterContext = React.createContext({})

const MasterContextProvider = (props) => {
    const [activeConnections, setActiveConnections] = useState(null);
    const [geometry, setGeometry] = useState(null)
    const [selectedConnection, setSelectedConnection] = useState(null)
    const [selectedColor, setSelectedColor] = useState(null)

    const [mapView, setMapView] = useState(null)

    const notyf = new Notyf({
        duration: 5 * 1000
    });

    const updateActiveConnections = () => {
        axiosInstance.get('/spojeni')
            .then((response) => {
                setActiveConnections(response.data.data)
            })
            .catch(() => {
                notyf.error('Omlouáme se v tuto chvíli není možné načíst aktuální data')
            })
    }

    useEffect(() => {
        updateActiveConnections()
        setInterval(updateActiveConnections, config.MAP_RELOAD)
    }, [])

    return (
        <MasterContext.Provider value={{
            activeConnections,
            geometry,
            selectedConnection,
            setSelectedConnection,
            setGeometry,
            selectedColor,
            setSelectedColor,
            mapView,
            setMapView
        }}>
            {props.children}
        </MasterContext.Provider>
    )
}

export default MasterContextProvider