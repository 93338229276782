import axios from 'axios'
import config from "../config";

const axiosInstance = axios.create({
    baseURL: config.OUR_API_URL,
    timeout: config.OUR_API_TIMEOUT
})

export const axiosInstanceOredo = axios.create({
    baseURL: config.OREDO_API_URL,
    timeout: config.OREDO_API_TIMEOUT
})

export default axiosInstance