class Geojson {
    parseSF(sf) {
        let string = sf;

        let type = string.substr(0, string.indexOf('('))

        string = string.substr(string.indexOf('(')).replaceAll('(', '')
        let datagroups = string.split(')').filter(group => group !== "")

        datagroups = datagroups.map(group => {
            return group.split(',').filter(group => group !== "").map((cor) => cor.split(' '))
        })

        datagroups = datagroups.map(a => {
            return a.map(b => {
                return b.map(c => {
                    return parseFloat(c)
                })
            })
        })

        if(datagroups.length === 1) {
            datagroups = datagroups[0]
        }

        if(type === 'LINESTRING') {
            type = 'LineString'
        }

        if(type === 'MULTILINESTRING') {
            type = 'MultiLineString'
        }

        return {
            type,
            coordinates: datagroups
        }
    }
}

export default new Geojson()