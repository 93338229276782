import React, {useEffect, useState} from "react";
import './css/main.scss';
import 'notyf/notyf.min.css'; // for React, Vue and Svelte
import Map from "./components/Map";
import axiosInstance from "./utils/axiosInstance";
import config from "./config";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import {MasterContext} from "./MasterContext";
import Connection from "./pages/connection";
import HomePage from "./pages/homepage";

const App = () => {
    const [connection, setConnection] = useState({})

    useEffect(() => {
        getDataForConnection('S-540370-14')
    }, [])

    const getDataForConnection = (identifier) =>{
        axiosInstance.get('/servicedetail?id='+identifier, {
            baseURL: 'https://tabule.oredo.cz/idspublicservices/api'
        })
            .then(response => {
                setConnection(response.data)
            })
    }

    return (
        <Router>
            <div className="layout-HomepageMapLayout">
                <div className="left-side">
                        <Switch>
                            <Route path="/" exact>
                                <HomePage/>
                            </Route>
                            <Route path="/spoj/:id">
                                <Connection/>
                            </Route>
                        </Switch>
                </div>
                <div className="right-side">
                    <div className="map-frame">
                        <MasterContext.Consumer>
                            {context => <Map activeConnections={context.activeConnections} geometry={context.geometry}/>}
                        </MasterContext.Consumer>
                    </div>
                    <div className="footer">
                        Vytvořili v rámci KHK Hackathonu <span className="team"> DeltaGroup (Vítek Falta, Matěj Půhoný)</span>
                    </div>
                </div>
            </div>
        </Router>
    )
}

export default App