import React, {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {MasterContext} from "../MasterContext";
import axiosInstance from "../utils/axiosInstance";
import Format from "../utils/Format";


const HomePage = () => {
    const history = useHistory()
    const context = useContext(MasterContext)

    const [searchData, setSearchData] = useState()
    const [searchValue, setSearchValue] = useState()

    const reset = () => {
        setSearchData(null)
        setSearchValue("")
        document.getElementById('searchbox').value = '';
    }

    useEffect(() => {
        context.setGeometry(null)
        context.setSelectedConnection(null)
    })

    const find = (value) => {
        setSearchValue(value)

        axiosInstance.get('/spojeni/hledat/'+value+'?limit=10')
            .then((response) => {
                console.log(response.data.data)
                setSearchData(response.data.data)
            })
    }

    return (
        <div className={"vertical-center page-homepage"}>
            <div className="welcome-field-wrapper">
                <h1>Vyhledej zpoždění svého IREDO spoje</h1>
                <div className="input-box">
                    <input id="searchbox" type="text" defaultValue="" placeholder="Zadejte číslo svého spoje" onChange={(e) => find(e.target.value)}/>
                    {
                        searchValue && (
                            <>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                                     onClick={reset}>
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12"/>
                                </svg>
                            </>
                        )
                    }
                </div>

                {
                    !searchData && searchValue && (
                        <div className="loader-box">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                            </svg>
                            <div className="text">Načítám data...</div>
                        </div>
                    )
                }

                {
                    searchData && (
                        <div className="found">
                            <h3>Vyhledané spoje</h3>

                            <div className="scrollbox">
                                {
                                    searchData.length === 0 && (
                                        <div>Nebyl nalezen spoj, nebo hledaný spoj zatím nevjel do systému IREDO</div>
                                    )
                                }

                                {
                                    searchData.map(connection => (
                                        <div className="connection" onClick={() => history.push('/spoj/'+connection.id)}>
                                            <div className="name">{connection.name}</div>
                                            <div className="provider">{connection.operator}</div>

                                            <div className="route">
                                                <div className="stop">
                                                    <div className="town">{connection.from}</div>
                                                    <div className="time">{connection.current_state && Format.time(new Date(connection.current_state.depart_time))}</div>
                                                </div>
                                                <div className="stop">
                                                    <div className="town">{connection.to}</div>
                                                    <div className="time">{connection.current_state && Format.time(new Date(connection.current_state.dest_time))}</div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default HomePage