import React, {useContext, useEffect, useState} from "react";
import Leaflet from "leaflet";
import config from "../config";
import Format from "../utils/Format";
import { useHistory } from "react-router-dom";
import {MasterContext} from "../MasterContext";
import Delay from '../utils/delay'

const Map = (props) => {
    const [mapView, setMapView] = useState()
    const [mapMarkers, setMapMarkers] = useState([])
    const [geoJson, setGeoJson] = useState()

    const history = useHistory()

    const context = useContext(MasterContext)

    useEffect(() => {
        const mymap = Leaflet.map('map', {
            preferCanvas: true
        }).setView([50.123219580802434,  16.011124480567393], 9);

        context.setMapView(mymap)
        setMapView(mymap)

        Leaflet.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
            attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
            maxZoom: 18,
            id: 'jetam/ckgj9my7z02ox19qba2v01shx',
            tileSize: 512,
            zoomOffset: -1,
            accessToken: config.MAPBOX_ACCESS_TOKEN
        }).addTo(mymap);

        const test = {
            geometry: {}
        }

    }, [])

    const cleanup = async () => {
        const markers = [...mapMarkers];

        await markers.forEach(marker => {
            marker.removeFrom(mapView)
        })

        setMapMarkers([])
    }

    useEffect(() => {
        setActiveConnections(props.activeConnections)
    }, [props.activeConnections])

    useEffect(() => {
        if(mapView) {
            if(geoJson) {
                geoJson.removeFrom(mapView)
            }

            setGeoJson(Leaflet.geoJSON(props.geometry, {
                color: context.selectedColor || 0
            }).addTo(mapView))
        }
    }, [props.geometry])

    const setActiveConnections = async (markers) => {
        await cleanup()

        if(mapView) {
            const newMapMarkers = []

            await markers.forEach((connection) => {
                const {current_state} = connection

                if(current_state && current_state.lat && current_state.lon) {
                    const mark = Leaflet.circleMarker([current_state.lat, current_state.lon], {
                        color: connection.vehicle_type === "A" ? 'transparent' : '#fff',
                        weight: 2.5,
                        fillColor: Delay.getDelayColor(current_state.delay),
                        fillOpacity: 1,
                        radius: 8,
                    }).addTo(mapView).on('click', () => {
                        history.push("/spoj/"+connection.id);
                        mapView.setView([current_state.lat, current_state.lon], 14)
                    })

                    mark.bindPopup("<div class='map-info-pop'>" +
                        "<div class='line'>" + Format.getVehicleType(connection.vehicle_type) + " " + connection.name +
                        "<div class='delay' style='background-color: "+ Delay.getDelayColor(current_state.delay)+"'>"+ Delay.getDelayString(current_state.delay) +"</div>" +
                        "</div>" +
                        "<div class='route'>" +
                        "<div class='town'><div class='name'>"+ connection.from +"</div><div class='time'>" + Format.time(new Date(current_state.depart_time)) + "</div></div>" +
                        "<div class='town'><div class='name'>"+ connection.to +"</div><div class='time'>" + Format.time(new Date(current_state.dest_time)) + "</div></div>" +
                        "</div>" +
                        "<div class='operator'>" + connection.operator + "</div>" +
                    "</div>")

                    mark.on('mouseover', () => mark.openPopup())

                    newMapMarkers.push(mark)
                }
            })

            setMapMarkers(newMapMarkers)
        }
    }

    return (
        <>
            <div id='map'></div>
        </>
    );
}

export default Map