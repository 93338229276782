export default {
    MAPBOX_ACCESS_TOKEN: "pk.eyJ1IjoiamV0YW0iLCJhIjoiY2tnajlvNDl6MHQ2MDJycW5sNjB5b3pmMyJ9.YIXKpQoD4hKVGv7YeWN-cg",

    OUR_API_URL: "https://hackathon.grimir.cz/api/",
    OUR_API_TIMEOUT: 10*1000,

    OREDO_API_URL: "https://tabule.oredo.cz/idspublicservices/api/",
    OREDO_API_TIMEOUT: 10*1000,

    DELAY_COLLORS: [
        {from: -1, color: '#87a2c7'},
        {from: 0, color: '#64a338'},
        {from: 2, color: '#ffcc00'},
        {from: 5, color: '#e03b24'},
    ],
    MAP_RELOAD: 10 * 1000
}